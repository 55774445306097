import axios from 'axios';

const jwt_token = localStorage.getItem('token');

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // baseURL: 'https://mymentorapp.herokuapp.com',
  baseURL: 'http://127.0.0.1:8000',
  headers: {
    Authorization: jwt_token ? 'jwt ' + jwt_token : null,
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  response => {
    console.log(response);
    return response;
  },
  error => {
    console.log(error);
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

export default axiosInstance;
