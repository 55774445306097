import AxiosMockAdapter from 'axios-mock-adapter';
import axios from './axios';

// ----------------------------------------------------------------------

const axiosMockAdapter = new AxiosMockAdapter(axios, {
  delayResponse: 0,
  onNoMatch: "passthrough"
});

axiosMockAdapter.onAny("/mockapi").reply(200);

// axiosMockAdapter.restore();
// axiosMockAdapter.reset();


export default axiosMockAdapter;
