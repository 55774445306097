import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  courses: [],
  post: null,
  recentCourses: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getCoursesSuccess(state, action) {
      state.isLoading = false;
      state.courses = action.payload;
    },

    // GET POST INFINITE
    getCoursesInitial(state, action) {
      state.isLoading = false;
      state.courses = action.payload;
    },

    getMoreCourses(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentCoursesSuccess(state, action) {
      state.isLoading = false;
      state.recentCourses = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreCourses } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCourses() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edu/course/');
      console.log(response);
      dispatch(slice.actions.getCoursesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCoursesInitial(index, step) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edu/course/', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const maxLength = response.data.maxLength;

      dispatch(slice.actions.getCoursesInitial(response.data));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(title) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edu/course/', {
        params: { title }
      });
      dispatch(slice.actions.getPostSuccess(response.data.post));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentCourses(title) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edu/course/', {
        params: { title }
      });

      dispatch(
        slice.actions.getRecentCoursesSuccess(response.data.recentCourses)
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function addCourse(data) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('addCourse');
      console.log(data);
      const response = await axios.post('/edu/course/', data);
      // dispatch(slice.actions.getCoursesSuccess(response.data));
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError());
    }
  };
}
