import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PATH_APP } from '~/routes/paths';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '~/components/LoadingScreen';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const { auth } = useSelector(state => state.user);
  console.log("GuestProtect", auth);

  // if (!isLoaded(auth)) {
  //   return <LoadingScreen />;
  // }


  const token = localStorage.getItem("token");
  console.log(token)

  if (token != null) {
    return <Redirect to={PATH_APP.root} />;
  }

  return children;
}

export default GuestProtect;
