import React, { useEffect } from 'react';
import ThemeConfig from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from '~/routes';
import ScrollToTop from '~/components/ScrollToTop';
import GoogleAnalytics from '~/components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from '~/components/NotistackProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { useDispatch } from 'react-redux';
import { refreshToken } from '~/redux/slices/user';
// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken());
    // dispatch(getProfile());
  }, [dispatch]);

  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <Router history={history}>
            <ScrollToTop />
            <GoogleAnalytics />
            {renderRoutes(routes)}
          </Router>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}

export default App;
