import faker from 'faker';
import { getImgCover, getImgFeed, getImgAvatar } from '~/utils/getImages';

 function userModelMapper(src){
   console.log(src);
    return  {
        id: 1, //src.id,
        profile_id: src.profile.id,
        role: 'Tester',
        displayName: src.user.first_name + ' ' + src.user.last_name,
        cover: {
          small: getImgCover(720, 1),
          medium: getImgCover(1200, 1)
        },
        position: 'UI Designer',
        follower: faker.random.number(),
        following: faker.random.number(),
        quote:
          'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
        country: src.country,
        address: src.address,
        state: 'Tamil Nadu',
        city: src.city,
        zipCode: src.zip,
        email: src.user.email,
        company: faker.company.companyName(),
        school: faker.company.companyName(),
        facebookLink: `https://www.facebook.com/caitlyn.kerluke`,
        instagramLink: `https://www.instagram.com/caitlyn.kerluke`,
        linkedinLink: `https://www.linkedin.com/in/caitlyn.kerluke`,
        twitterLink: `https://www.twitter.com/caitlyn.kerluke`
      };
}

function updateAccountMapper(src) {
  console.log('SOURCE', src);
  return {
    id: 1,
    gender: 'male',
    domain: 'Dev',
    language: 'Tamil',
    title: 'Mr.',
    dob: '30-05-1994',
    address: `69 csi 8th street`,
    country: faker.address.country(),
    zip: 641034,
    city: 'cbe'
  };
}
export { userModelMapper, updateAccountMapper };
